var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.myDataObj)?_c('div',{staticClass:"container",attrs:{"id":"containerId"}},[(_vm.myDataObj && _vm.myDataObj.coverImg)?_c('img',{staticClass:"coverImg",attrs:{"src":_vm.myDataObj.coverImg}}):_c('div',{staticClass:"navContainer"},[_c('div',{staticClass:"navTitle"},[_vm._v("测评报告详情")])]),_c('BaseInfoContainer',{attrs:{"myDataObj":_vm.myDataObj,"isHiddenBtn":_vm.isHiddenBtn,"isExport":_vm.isExport,"reportType":1}}),(_vm.myDataObj.overview)?_c('div',{staticClass:"dimensionResultContainer"},[_c('TitleBar',{attrs:{"title":"工具介绍"}}),_c('ContentBox',{attrs:{"content":_vm.myDataObj.overview,"isBackGroundColor":true}})],1):_vm._e(),(_vm.myDataObj.reportObj)?_c('div',{staticClass:"reportContainer"},[_c('TitleBar',{staticStyle:{"margin-top":"0","width":"auto"},attrs:{"title":_vm.myDataObj.reportObj.title}}),_c('div',{staticClass:"reportCenterDiv"},[_c('div',{staticClass:"circleDiv"},[_c('img',{staticClass:"circleHeartImg",attrs:{"src":_vm.wsHeartImg}}),_c('div',{staticClass:"circleScoreDiv"},[_vm._v(" "+_vm._s(_vm.myDataObj.reportObj.score || 0)+"分 ")])]),_c('div',{staticClass:"rectDiv"},_vm._l((_vm.unitRectDivList),function(item,index){return _c('div',{key:index,staticClass:"unitRectDiv"},[_c('div',{staticClass:"deepUnitRectDiv",style:({ width: item.deepWidth + 'px' })})])}),0)]),(
        _vm.myDataObj.reportObj.tagTitle &&
          _vm.myDataObj.reportObj.tagTitle.length > 0
      )?_c('div',{staticClass:"reportRightDiv",style:({ backgroundColor: _vm.myDataObj.reportObj.tagBgColor })},[_vm._v(" "+_vm._s(_vm.myDataObj.reportObj.tagTitle)+" ")]):_vm._e()],1):_vm._e(),(_vm.myDataObj.totalResultList)?_c('div',{staticClass:"totalResultContainer"},_vm._l((_vm.myDataObj.totalResultList),function(item,index){return _c('div',{key:index,staticClass:"totalResultItem"},[_c('TitleBar',{attrs:{"title":item.title}}),_c('ContentBox',{attrs:{"content":item.content,"isBackGroundColor":true}})],1)}),0):_vm._e(),(_vm.myDataObj.dimensionChartObj)?_c('div',{staticClass:"dimensionChartResultContainer"},[_c('TitleBar',{attrs:{"title":_vm.myDataObj.dimensionChartObj.title}}),(_vm.myDataObj.dimensionChartObj.chartData)?_c('div',{staticClass:"dimensionChartView"},[_c('div',{ref:"myChart",staticClass:"barChart",attrs:{"id":"myChart"}})]):_vm._e()],1):_vm._e(),(_vm.myDataObj.dimensionResultObj)?_c('div',{staticClass:"dimensionResultContainer"},[_c('TitleBar',{attrs:{"title":_vm.myDataObj.dimensionResultObj.title}}),_vm._l((_vm.myDataObj.dimensionResultObj.dimensionList),function(item,index){return _c('div',{key:index,staticClass:"dimensionResultItem"},[_c('div',{staticClass:"dimensionTopDiv"},[(!_vm.isExport)?_c('div',{staticClass:"dimensionTopSegmentDiv"}):_vm._e(),_c('div',{staticClass:"dimensionTopContentDiv"},[_vm._v(" "+_vm._s(item.title || "")+"/"+_vm._s(item.score || "")+"/ "),_c('span',[_vm._v(_vm._s(item.tag || ""))])])]),(item.overview && item.overview.length > 0)?_c('ContentBox',{attrs:{"content":item.overview,"isBackGroundColor":true}}):_vm._e(),(
          item.explain &&
            item.explain.length > 0 &&
            !item.explain.match(/^[ ]+$/)
        )?_c('ContentBox',{attrs:{"content":item.explain,"imgSrc":_vm.wsListResultImg,"isBackGroundColor":true}}):_vm._e(),(
          item.advise &&
            item.advise.length > 0 &&
            !item.advise.match(/^[ ]+$/)
        )?_c('ContentBox',{attrs:{"content":item.advise,"imgSrc":_vm.wsListSuggestImg,"isBackGroundColor":true}}):_vm._e()],1)})],2):_vm._e(),(_vm.myDataObj && _vm.myDataObj.scienceObj && _vm.myDataObj.scienceObj.content)?_c('div',{staticClass:"scienceContainer"},[_c('TitleBar',{attrs:{"title":_vm.myDataObj.scienceObj.title}}),_c('ContentBox',{attrs:{"content":_vm.myDataObj.scienceObj.content,"isBackGroundColor":true}})],1):_vm._e(),(_vm.myDataObj && _vm.myDataObj.backCoverImg)?_c('img',{staticClass:"coverImg",attrs:{"src":_vm.myDataObj.backCoverImg}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }