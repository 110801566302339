<template>
  <div id="containerId" class="container" v-if="myDataObj">
    <!-- 封面 -->
    <img
      v-if="myDataObj && myDataObj.coverImg"
      :src="myDataObj.coverImg"
      class="coverImg"
    />
    <!-- 导航条容器 -->
    <div v-else class="navContainer">
      <div class="navTitle">测评报告详情</div>
    </div>
    <!-- 基本信息容器 -->
    <BaseInfoContainer
      :myDataObj="myDataObj"
      :isHiddenBtn="isHiddenBtn"
      :isExport="isExport"
      :reportType="1"
    />
    <!-- 工具介绍 -->
    <div class="dimensionResultContainer" v-if="myDataObj.overview">
      <TitleBar title="工具介绍" />
      <ContentBox
        :content="myDataObj.overview"
        :isBackGroundColor="true"
      />
    </div>
    <!-- 报告容器 -->
    <div class="reportContainer" v-if="myDataObj.reportObj">
      <TitleBar
        :title="myDataObj.reportObj.title"
        style="margin-top: 0; width: auto"
      />
      <!-- <div class="reportLeftDiv">
        <div class="commonLineDiv"></div>
        <div class="commonTitleDiv ws_oneLine">
          {{ myDataObj.reportObj.title || "" }}
        </div>
      </div> -->
      <div class="reportCenterDiv">
        <div class="circleDiv">
          <img :src="wsHeartImg" class="circleHeartImg" />
          <div class="circleScoreDiv">
            {{ myDataObj.reportObj.score || 0 }}分
          </div>
        </div>
        <div class="rectDiv">
          <div
            class="unitRectDiv"
            v-for="(item, index) in unitRectDivList"
            :key="index"
          >
            <div
              class="deepUnitRectDiv"
              :style="{ width: item.deepWidth + 'px' }"
            ></div>
          </div>
        </div>
      </div>
      <div
        v-if="
          myDataObj.reportObj.tagTitle &&
            myDataObj.reportObj.tagTitle.length > 0
        "
        class="reportRightDiv"
        :style="{ backgroundColor: myDataObj.reportObj.tagBgColor }"
      >
        {{ myDataObj.reportObj.tagTitle }}
      </div>
    </div>
    <!-- 总结果容器 -->
    <div class="totalResultContainer" v-if="myDataObj.totalResultList">
      <div
        class="totalResultItem"
        v-for="(item, index) in myDataObj.totalResultList"
        :key="index"
      >
        <TitleBar :title="item.title" />
        <ContentBox :content="item.content" :isBackGroundColor="true" />
      </div>
    </div>
    <!-- 维度测评结果容器(画图) -->
    <div
      class="dimensionChartResultContainer"
      v-if="myDataObj.dimensionChartObj"
    >
      <TitleBar :title="myDataObj.dimensionChartObj.title" />
      <div
        class="dimensionChartView"
        v-if="myDataObj.dimensionChartObj.chartData"
      >
        <!-- bar条形图  pie饼状图 line折线图 radar雷达图-->
        <div id="myChart" ref="myChart" class="barChart"></div>
      </div>
    </div>
    <!-- 维度结果/解释 容器 -->
    <div class="dimensionResultContainer" v-if="myDataObj.dimensionResultObj">
      <TitleBar :title="myDataObj.dimensionResultObj.title" />
      <div
        class="dimensionResultItem"
        v-for="(item, index) in myDataObj.dimensionResultObj.dimensionList"
        :key="index"
      >
        <div class="dimensionTopDiv">
          <div v-if="!isExport" class="dimensionTopSegmentDiv"></div>
          <div class="dimensionTopContentDiv">
            {{ item.title || "" }}/{{ item.score || "" }}/
            <span>{{ item.tag || "" }}</span>
          </div>
        </div>
        <ContentBox
          v-if="item.overview && item.overview.length > 0"
          :content="item.overview"
          :isBackGroundColor="true"
        />
        <ContentBox
          v-if="
            item.explain &&
              item.explain.length > 0 &&
              !item.explain.match(/^[ ]+$/)
          "
          :content="item.explain"
          :imgSrc="wsListResultImg"
          :isBackGroundColor="true"
        />
        <ContentBox
          v-if="
            item.advise &&
              item.advise.length > 0 &&
              !item.advise.match(/^[ ]+$/)
          "
          :content="item.advise"
          :imgSrc="wsListSuggestImg"
          :isBackGroundColor="true"
        />
      </div>
    </div>
    <!-- 科普知识容器 -->
    <div class="scienceContainer" v-if="myDataObj && myDataObj.scienceObj && myDataObj.scienceObj.content">
      <TitleBar :title="myDataObj.scienceObj.title"></TitleBar>
      <ContentBox
        :content="myDataObj.scienceObj.content"
        :isBackGroundColor="true"
      />
      <!-- <div class="commonContentDiv">
        {{ myDataObj.scienceObj.content || "" }}
      </div> -->
    </div>
    <!-- 封底 -->
    <img
      v-if="myDataObj && myDataObj.backCoverImg"
      :src="myDataObj.backCoverImg"
      class="coverImg"
    />
  </div>
</template>
<script>
import htmlToPdf from "../../utils/htmlToPdf";
// import BaseInfoContainer from "../../components/baseInfoContainer";
// import TitleBar from "../../components/titleBar.vue";
// import ContentBox from "../../components/contentBox.vue";
import { mixins } from "../../utils/mixins";
import { getPersonReportData } from "../../api/report";
export default {
  mixins: [mixins],
  name: "CaseReport",
  // components: {
  //   BaseInfoContainer,
  //   TitleBar,
  //   ContentBox,
  // },
  data() {
    return {
      wsHeartImg: require("../../assets/images/caseReportNew/list_heart.png"), //心图片
      myDataObj: undefined, //个案网络请求回来的数据
      unitRectDivList: [
        {
          type: 0,
          ratio: 1,
          deepWidth: 0,
        },
        {
          type: 1,
          ratio: 1,
          deepWidth: 0,
        },
        {
          type: 2,
          ratio: 0.5,
          deepWidth: 0,
        },
        {
          type: 3,
          ratio: 0,
          deepWidth: 0,
        },
        {
          type: 4,
          ratio: 0,
          deepWidth: 0,
        },
        {
          type: 5,
          ratio: 0,
          deepWidth: 0,
        },
        {
          type: 6,
          ratio: 0,
          deepWidth: 0,
        },
        {
          type: 7,
          ratio: 0,
          deepWidth: 0,
        },
        {
          type: 8,
          ratio: 0,
          deepWidth: 0,
        },
        {
          type: 9,
          ratio: 0,
          deepWidth: 0,
        },
      ],
    };
  },
  methods: {
    //导出报告按钮点击事件
    ws_exportReportBtnClickAction() {
      //导出PDF
      // htmlToPdf.downloadPDF(document.querySelector("#containerId"), "我的PDF");
      htmlToPdf.printOut(document.querySelector("#containerId"));
    },
    //计算分数
    ws_getReportScoreActionWithScore(reportScore, reportTotalScore) {
      reportScore = reportScore || 0;
      reportTotalScore = reportTotalScore || 0.01;
      reportScore = (reportScore / reportTotalScore) * 100;

      //   var wsCount =  parseInt(reportScore /10);
      var wsCount = Math.floor(reportScore / 10);
      var wsRemainder = reportScore % 10;
      wsCount = wsRemainder > 0 ? wsCount + 1 : wsCount;
      var tempList = this.unitRectDivList;
      for (var i = 0; i < tempList.length; i++) {
        var tempDic = tempList[i];
        if (i <= wsCount - 1) {
          tempDic.ratio = 1;
          tempDic.deepWidth = 11;
          if (wsRemainder > 0 && i == wsCount - 1) {
            tempDic.ratio = wsRemainder / 10;
            tempDic.deepWidth = (11 * wsRemainder) / 10;
          }
        } else {
          tempDic.ratio = 0;
          tempDic.deepWidth = 0;
        }
      }
      this.unitRectDivList = tempList;
    },
    //渲染echarts事件
    ws_drawEchartsAction() {
      var wsOption = this.myDataObj.dimensionChartObj.chartData.option;
      // var charts = this.$echarts.init(document.getElementById('myChart'));
      let charts = this.$echarts.init(this.$refs.myChart);
      charts.setOption(wsOption);
    },
    //个案数据网络请求
    ws_geCaseReportDataNetWorkAction() {
      var that = this;
      getPersonReportData(that.queryParams).then((res) => {
        //成功
        if (res.code == 200) {
          that.myDataObj = res.data;
          if (this.myDataObj.overview) {
            this.myDataObj.overview = this.myDataObj.overview.replace(/\n/g, "<br>");
          }
          if (this.myDataObj.scienceObj && this.myDataObj.scienceObj.content) {
            this.myDataObj.scienceObj.content = this.myDataObj.scienceObj.content.replace(/\n/g, "<br>");
          }
          if (this.myDataObj.summary) {
            this.myDataObj.summary = this.myDataObj.summary.replace(/\n/g, "<br>");
          }
          if (that.myDataObj.reportObj) {
            that.ws_getReportScoreActionWithScore(
              that.myDataObj.reportObj.score,
              that.myDataObj.reportObj.totalScore
            );
          }

          //渲染echart图
          that.$nextTick(() => {
            that.ws_drawEchartsAction();
          });
        } else {
          // uni.showToast({
          // 	title: res.statusCode != 200 ? res.statusCode : res.data.msg,
          // 	icon: 'none'
          // });
        }
      });
    },
  },
  mounted() {
    //个案数据网络请求
    this.ws_geCaseReportDataNetWorkAction();
    //实现自适应部分
    window.onresize = () => {
      // 基于准备好的dom，初始化echarts实例
      // let myChart = this.$echarts.init(document.getElementById("myChart"));
      let myChart = this.$echarts.init(this.$refs.myChart);
      myChart.resize();
    };
  },
};
</script>

<style lang="scss" scoped>
// @import "~styles/variables.scss";
@import "~styles/commonPage.scss";
// .container {
//   width: 100%;
//   min-height: 100vh;
//   padding-bottom: 0.4rem;
//   background: #ebeff8;
//   //导航条容器
//   .navContainer {
//     height: 0.6rem;
//     line-height: 0.6rem;
//     text-align: center;
//     background-color: $ws-mainColor;
//     font-size: 0.32rem;
//     font-weight: bold;
//     color: #fff;
//   }

//   // 报告容器
//   .reportContainer {
//     //   height: 2rem;
//     background-color: #fff;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: space-between;
//     // padding: 0rem 0.4rem;
//     padding: 0.2rem 0.4rem;
//     .reportLeftDiv {
//       display: flex;
//       align-items: center;
//     }
//     .reportCenterDiv {
//       display: flex;
//       align-items: center;
//       .circleDiv {
//         border: 2px solid $ws-mainColor;
//         height: 50px; //40px;
//         width: 50px; //40px;
//         border-radius: 25px; //20px;
//         background-color: #fff;
//         z-index: 1;
//         // line-height: 36px;
//         // text-align: center;
//         // font-size: 13px;
//         // color: $ws-mainColor;
//         // font-weight: bold;
//         position: relative;
//         .circleHeartImg {
//           position: absolute;
//           width: 40px;
//           height: 40px;
//           left: 50%;
//           top: 50%;
//           transform: translate(-50%, -50%);
//           // background-color: pink;
//         }
//         .circleScoreDiv {
//           position: absolute;
//           text-align: center;
//           font-size: 12px;
//           -webkit-text-size-adjust: none;
//           color: #fff;
//           font-weight: bold;
//           left: 50%;
//           top: 50%;
//           min-width: 40px;
//           transform: translate(-50%, -50%);
//         }
//       }
//       .rectDiv {
//         margin-left: -0.2rem;
//         height: 25px;
//         min-width: 100px;
//         border: 2px solid $ws-mainColor;
//         display: flex;
//         padding-left: 10px;
//         display: flex;
//         align-items: center;
//         .unitRectDiv {
//           background-color: #aad7fd;
//           width: 15px;
//           height: 15px;
//           border-right: 4px solid #fff;
//           .deepUnitRectDiv {
//             // width: 11px;
//             width: 0px;
//             height: 15px;
//             background-color: $ws-mainColor;
//           }
//         }
//       }
//     }
//     .reportRightDiv {
//       //   height: 0.4rem;
//       min-height: 0.4rem;
//       line-height: 0.4rem;
//       padding: 0 0.3rem;
//       font-size: 0.24rem;
//       color: #fff;
//       border-radius: 0.2rem;
//       text-align: center;
//       background-color: $ws-mainColor;
//     }
//   }

//   //总结果容器
//   .totalResultContainer {
//     padding: 0rem 0.4rem;
//     .totalResultItem {
//       .totalResultTitleDiv {
//         display: flex;
//         align-items: center;
//         margin: 0.4rem 0 0.2rem;
//       }
//     }
//   }
//   // 维度测评结果容器(画图)
//   .dimensionChartResultContainer {
//     padding: 0rem 0.4rem;
//     .dimensionChartTitleDiv {
//       display: flex;
//       align-items: center;
//       margin: 0.4rem 0 0.2rem;
//     }
//     .dimensionChartView {
//       //柱状图
//       .barChart {
//         height: 300px;
//         // width: 100%;
//         max-width: 700px;
//       }
//       // width: 200px;
//       // height: 100px;
//       // background-color: pink;
//       // .wsEchart {
//       //   width: 500px;
//       //   height: 300px;
//       //   background-color: red;
//       // }
//     }
//   }

//   //维度测评结果容器
//   .dimensionResultContainer {
//     padding: 0rem 0.4rem;
//     .dimensionTitleDiv {
//       display: flex;
//       align-items: center;
//       margin: 0.4rem 0 0.2rem;
//     }
//     .dimensionResultItem {
//       margin-bottom: 0.4rem;
//       .dimensionTopDiv {
//         background-color: #c9e5fd;
//         padding: 0.2rem;
//         border-radius: 0.12rem;
//         margin-bottom: 0.2rem;
//         position: relative;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         .dimensionTopSegmentDiv {
//           position: absolute;
//           left: 0.3rem;
//           right: 0.3rem;
//           top: 50%;
//           border: 0.5px dashed $ws-text-color-black;
//           // color: $ws-text-color-black;
//         }
//         .dimensionTopContentDiv {
//           display: flex;
//           align-items: center;
//           background-color: #c9e5fd;
//           padding: 0 0.2rem;
//           z-index: 2;
//           font-size: 0.28rem;
//           //   line-height: .35rem;
//           line-height: 0.4rem;
//           max-width: calc(100% - 0.5rem);
//         }
//       }
//     }
//   }
//   //科普知识容器
//   .scienceContainer {
//     padding: 0rem 0.4rem;
//     .scienceTitleDiv {
//       display: flex;
//       align-items: center;
//       margin: 0.4rem 0 0.2rem;
//     }
//   }
// }

// /* 语法  @media screen and (max-width:768px) and (min-width:320px){}*/
// @media screen and (max-width: 768px) {
//   /* 1.在超小屏设备的时候 768px以下   当前容器的宽度100%   并且背景设置为蓝色 */
//   .container {
//     //导航条容器
//     .navContainer {
//       display: none;
//     }
//     // 基本信息容器
//     .baseInfoContainer {
//       .baseInfoTitle {
//         text-align: left;
//       }
//       .baseInfoList {
//         display: flex;
//         justify-content: left;
//         .baseInfoItem {
//           width: 50%;
//         }
//       }
//       .baseInfoBtnList {
//         display: none;
//       }
//     }
//     // 报告容器
//     .reportContainer {
//       flex-direction: column;
//       align-items: start;
//       justify-content: left;
//       padding: 0.2rem 0.2rem;
//       .reportCenterDiv {
//         margin: 0.2rem 0;
//       }
//     }
//     //总结果容器
//     .totalResultContainer {
//       padding: 0rem 0.2rem;
//     }
//     //维度测评结果容器(画图)
//     .dimensionChartResultContainer {
//       padding: 0rem 0.2rem;
//     }
//     //维度测评结果容器
//     .dimensionResultContainer {
//       padding: 0rem 0.2rem;
//     }
//     //科普知识容器
//     .scienceContainer {
//       padding: 0rem 0.2rem;
//     }
//   }
// }

// @media screen and (min-width: 768px) and (max-width: 992px) {
//   /* 2.在小屏设备的时候 768px-992px  当前容器的宽度750px    并且背景设置为绿色 */
// }

// @media screen and (min-width: 992px) and (max-width: 1200px) {
//   /* 3.在中屏设备的时候 992px-1200px  当前容器的宽度970px    并且背景设置为红色 */
// }

// @media screen and (min-width: 1200px) {
//   /* 4.在中屏设备的时候 1200px以上   当前容器的宽度1170px    并且背景设置为黄色 */
// }
</style>
